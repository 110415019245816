* {
  margin: 0;
}

.App {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-logo {
  height: 5rem;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation-iteration-count: 1;
    animation: App-logo-spin  60s linear;
  }
}

.App-header {
  background-color: #7b7b7b;
  min-height:  calc(22rem);;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.camera{
  width: 14rem;
  position: relative;
  bottom: 5rem;
}

.plugin{
  border-radius: 8px;
  overflow: hidden;
  visibility: visible;
  box-shadow: 0 0 20px 0 black;
  width: 600px;
  height: 733px;
  border: 2px solid grey;
}

.pluginContainer{
  display: flex;
  justify-content: center;
  position: absolute;
  top: -11rem;
}

.text-center{
  z-index:9999;
}

.jumbotron {
  height: 30em;
  margin-bottom: 2rem;
  background-color: rgb(123, 123, 123);
  border-radius: 0;
  padding-top: 0;
}

.footer {
  margin-top: 1rem;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
  text-shadow: 0 0 20px;
  animation: pulsate 5.2s linear infinite;
  box-shadow: 147px 206px 60px 20px;
}

.container{
  height:100vh;
  padding-left: 0;
  padding-right: 0;
}

.card-info{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: larger;
  top: -16rem;
}

.card{
  width: 37rem;
  box-shadow: 3px -20px 30px -10px #545b62;
  border: 0;
}

.form-card{
  width: 37rem;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  box-shadow: 9px 14px 20px 0px #adb5bd;
}

.form-group{
  flex: 0 0 100%;
  max-width: 100%;
}

.form-row>.col, .form-row>[class*=col-] {
  padding-right: 25px;
  padding-left: 25px;
}

.form-group-text{
  flex: 0 0 33.33%;
  padding-left: 20px;
  padding-right: 20px;
}

.nav-item a{
  color: white;
}

.card-header {
  background-color: rgb(98, 99, 101);
}

.send{
  margin-left: 15rem;
  margin-right: 15rem;
  margin-bottom: 1rem;
}

.card-text {
  min-height: 11rem;
}

.soon{
  position: relative;
  top: 1.8rem;
  left: 2rem;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 2rem;
  border-radius: 0.75rem;
  text-shadow: -30px -5px 20px;
  -webkit-animation: pulsate 5.2s linear infinite;
  animation: pulsate 5.2s linear infinite;
}

.camera-soon-container{
  display: flex;
}

/*@-webkit-keyframes pulsate {*/
/*  50% {color: #fff; text-shadow: 0 -1px rgba(0,0,0,.3), 0 0 5px #ffd, 0 0 8px #fff;}*/
/*}*/
/*@keyframes pulsate {*/
/*  50% {color: #fff; text-shadow: 0 -1px rgba(0,0,0,.3), 0 0 5px #ffd, 0 0 8px #fff;}*/
/*}*/

@media(min-width: 320px) and (max-width: 1820px){
  .pluginContainer{
    top: -8rem;
  }
}

@media(min-width: 1821px) and (max-width: 2020px){
  .pluginContainer {
    left: 6rem;
  }
}

@media(min-width: 1px) and (max-width: 1820px){
  .pluginContainer {
    left: inherit;
  }
}

@media(min-width: 1200px) and (max-width: 1270px){
  .card-info {
    transform: scale(0.9, 0.9);
  }
}

@media(min-width: 621px) and (max-width: 768px){
  .display-1 {
    font-size: 5rem;
  }
  .soon {
    font-size: 1.5rem
  }
}

@media(min-width: 1200px) {
  .card-info{
    top: -5rem;
  }
}

@media(min-width: 620px) and (max-width: 1199px){
  .card-info{
    top: 39rem;
  }
}

@media(min-width: 520px) and (max-width: 620px){
  .display-1 {
    font-size: 4rem;
  }
  .App {
    transform: scale(0.8, 0.8);
  }
  .pluginContainer {
    top: -11rem;
  }
  .card{
    width: 30rem;
  }
  .form-card {
    width: 30rem;
  }
  .camera {
    left: 2rem;
  }
  .send {
    margin-left: 12rem;
    margin-right: 12rem;
  }
  .card-info{
    top: 29rem;
  }
}

@media(min-width: 420px) and (max-width: 519px){
  .display-1 {
    font-size: 3.3rem;
    top: -3.5rem;
    position: relative;
    box-shadow: 147px 150px 60px 20px;
  }
  .App-logo {
    height: 2.65rem;
  }
  .pluginContainer {
    top: -21.5rem;
  }
  .soon {
    font-size: 1.2rem;
    top: -2.8rem;
  }
  .App {
    transform: scale(0.6, 0.6);
  }
  .card {
    width: 25rem;
  }
  .form-card {
    width: 25rem;
  }
  .camera{
    width: 9rem;
    bottom: 7rem;
    left: 1rem;
  }
  .send {
    margin-left: 10rem;
    margin-right: 10rem;
  }
  .card-info{
    top: 16rem;
  }
}

@media(min-width: 120px) and (max-width: 419px){
  .display-1 {
    font-size: 2.5rem;
    top: -4rem;
    position: relative;
    box-shadow: 147px 130px 60px 20px;
  }
  .pluginContainer {
    top: -27rem
  }
  .camera {
    width: 8rem;
    left: 1rem;
    bottom: 7rem;
  }
  .soon {
    top: -3.2rem;
    left: 0rem;
    font-size: 1rem;
  }
  .App {
    transform: scale(0.5, 0.5);
  }
  .card{
    width: 20rem;
  }
  .form-card {
    width: 20rem;
  }
  .send {
    margin-left: 8rem;
    margin-right: 8rem;
    margin-bottom: 1rem;
  }
  .App-logo {
    height: 2.5rem;
  }
  .card-info {
    font-size: medium;
    top: 10rem;
  }
}


ol {
  counter-reset: my-counter;
  list-style: none;
  padding-left: 40px;
}
ol li {
  margin: 0 0 0 0;
  counter-increment: my-counter;
  position: relative;
}
ol li::before {
  content: counter(my-counter);
  color: #ffffff;
  font-size: 1.5rem;
  position: absolute;
  --size: 25px;
  left: calc(-1.5 * var(--size));
  line-height: var(--size);
  width:calc(var(--size) + 5px);
  height: var(--size);
  top: 0;
  transform: rotate(-10deg);
  background: #857463;
  border-radius: 50%;
  text-align: center;
  box-shadow: 1px 1px 0 #999;
}

.scope{
  color: #857463;
  font-weight: 300;
}

.talk-chat{
  color: #9a9086;
  font-weight: 700;
  cursor: pointer;
}

.word-talk-chat{
  font-weight: 500;
  color: #80644f;
}

.o-non{
  font-size: 0.01rem;
  color: #818181;
}

